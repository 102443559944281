import React from 'react'
import Sidebar from "../../components/admin/sidebar/Sidebar"

function Home() {
  return (
    <div>
      <Sidebar/>
      <h4>Welcome To Admin Home Page</h4>
    </div>
  )
}

export default Home
